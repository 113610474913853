import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AuthenticationGuard from "./hocs/AuthenticationGuard";
import PageLoader from "./components/PageLoader";
import FlashValuation from "./components/FlashValuation/FlashValuation";

const LoginPage = lazy(() => import("./pages/LoginPage"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const BusinessPage = lazy(() => import("./pages/BusinessPage"));
const FlashValuationPage = lazy(() => import("./pages/FlashValuationPage"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const BusinessBuilderPage = lazy(() => import("./pages/BusinessBuilderPage"));

const SunsetPlanRouter = lazy(() => import("./router/SunsetPlanRouter"));
const VProRouter = lazy(() => import("./router/VProRouter"));

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {/* Unauthenticated Routes */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/flash" element={<FlashValuationPage />} />
        <Route path="/wixflash" element={<FlashValuation />} />

        {/* Authentication Only Routes */}
        <Route path="/vpro/*" element={<AuthenticationGuard component={VProRouter} />} />
        <Route path="/sunset-plan/*" element={<AuthenticationGuard component={SunsetPlanRouter} />} />

        <Route path="/business" element={<AuthenticationGuard component={BusinessPage} />} />
        <Route path="/dashboard" element={<AuthenticationGuard component={DashboardPage} />} />
        <Route path="/business-builder" element={<AuthenticationGuard component={BusinessBuilderPage} />} />

        {/* Error Routes */}
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
